<template>
  <div class="patentInfo">
    <div class="cont-left">
      <div class="upload">
        <img class="img" v-if="ruleForm.avatar" :src="ruleForm.avatar" />
        <img class="img" v-else src="@/assets/img/user/user.png" />
        <div class="name">{{ ruleForm.name }}</div>
        <div class="age">
          {{ ruleForm.age }}岁
          <img
            class="sex-img"
            v-if="ruleForm.sex == '女'"
            src="@/assets/img/ions/sex1.png"
          />
          <img
            class="sex-img"
            v-else-if="ruleForm.sex == '男'"
            src="@/assets/img/ions/sex2.png"
          />
        </div>
        <!--        <div class="uid">病案号：{{ ruleForm.case_no }}</div>-->
      </div>
      <div class="info">
        <div class="item">
          <img class="icon-img" src="@/assets/img/ions/binganhao.png" />
          病案号:
          {{ ruleForm.case_no }}
        </div>
        <div class="item">
          <img class="icon-img" src="@/assets/img/ions/zrys.png" />
          责任医师:
          {{ ruleForm.duty_doctor_name }}
        </div>
        <div class="item">
          <img class="icon-img" src="@/assets/img/ions/zrhs.png" />
          责任护士:
          {{ ruleForm.duty_nurse_name }}
        </div>
        <div class="item">
          <img class="icon-img" src="@/assets/img/ions/yuanqu.png" />
          院区:
          {{ ruleForm.hospital_area_name }}
        </div>
      </div>
      <!--      <el-form-->
      <!--        ref="ruleFormRef"-->
      <!--        :model="ruleForm"-->
      <!--        label-position="left"-->
      <!--        class="demo-ruleForm"-->
      <!--        size="large"-->
      <!--      >-->
      <!--        <el-form-item label="病案号：">-->
      <!--          {{ ruleForm.case_no }}-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="责任医师：">-->
      <!--          {{ ruleForm.duty_doctor_name }}-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="责任护士：">-->
      <!--          {{ ruleForm.duty_nurse_name }}-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="院区：">-->
      <!--          {{ ruleForm.hospital_area_name }}-->
      <!--        </el-form-item>-->
      <!--        &lt;!&ndash; <el-form-item label="接诊区：" prop="name">-->
      <!--          <el-input v-model="ruleForm.name"></el-input>-->
      <!--        </el-form-item> &ndash;&gt;-->
      <!--      </el-form>-->
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { getConstHei } from '@/utils/tool.js'
import service from '@/utils/request'
import { useRoute } from 'vue-router'
export default {
  name: 'patentInfo',
  setup() {
    const state = reactive({
      ruleForm: {},
      infoHei: '',
    })

    onMounted(() => {
      state.infoHei = getConstHei()
      getInfoData()
    })

    const route = useRoute()
    const getInfoData = async () => {
      let res = await service.post('/api/patient/get_patient_detail', {
        patient_id: route.params.id,
      })
      if (res.code === 0) {
        state.ruleForm = res.data
      }
    }

    return {
      ...toRefs(state),
      getInfoData,
    }
  },
}
</script>

<style scoped lang="scss">
.cont-left {
  .upload {
    font-size: 14px;
    font-family: 'Source Han Sans CN-Regula';
    margin-bottom: 16px;
    .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-top: 20px;
    }
    .sex-img {
      width: 18px;
      height: 18px;
    }
    .name {
      font-family: 'Source Han Sans CN-Medium';
      font-size: 24px;
      color: #3166ae;
      margin-top: 20px;
    }
    .age {
      color: #3166ae;
      margin-right: 6px;
      margin: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .uid {
      color: #3166ae;
    }
  }
  .el-form {
    &:deep(.el-form-item__label),
    &:deep(.el-input__inner) {
      color: #3166ae;
    }
  }
  .info {
    margin-top: 30px;
    .item {
      color: #3166ae;
      display: flex;
      align-items: center;
      line-height: 32px;
      letter-spacing: 2px;
      .icon-img {
        margin-right: 5px;
      }
    }
  }
}
</style>
