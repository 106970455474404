<template>
  <div :style="{ height: infoHei }" class="patentInfo">
    <el-scrollbar>
      <div class="padRight-20">
        <div class="cont flex-start">
          <div class="patInfo">
            <patInfo ref="patInfoRef"></patInfo>
          </div>
          <div class="cont-main col999">
            <div class="updateBox">
              <el-button class="color-main" type="primary" @click="modParient">
                <i class="fa fa-edit"></i>修改
              </el-button>
              <el-button class="color-main" type="primary" @click="openQrcode">
                <i class="fa fa-qrcode"></i>查看签到码
              </el-button>
            </div>
            <div class="userItem">
              <div class="user-tit">his绑定</div>
              <div class="user-info flex-between">
                <div class="info-item-box wd-2">
                  <div class="info-item flex-between">
                    <div class="lable">hisID：</div>
                    <div class="cont">{{ ruleForm.hospital_his_id }}</div>
                  </div>
                </div>
                <div class="info-item-box norig">
                  <div class="info-item flex-between">
                    <div class="lable">诊疗卡号：</div>
                    <div class="cont">{{ ruleForm.consultation_no }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="userItem">
              <div class="user-tit">个人信息</div>
              <div class="user-info flex-between">
                <div class="info-item-box">
                  <div class="info-item flex-between">
                    <div class="lable">姓名：</div>
                    <div class="cont">{{ ruleForm.name }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">民族：</div>
                    <div class="cont">
                      {{ gitDictVal('102000000', ruleForm.dict_nation) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">证件号码：</div>
                    <div class="cont">{{ ruleForm.cert_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">家属姓名：</div>
                    <div class="cont">{{ ruleForm.contact_person_name }}</div>
                  </div>
                </div>
                <div class="info-item-box">
                  <div class="info-item flex-between">
                    <div class="lable">性别：</div>
                    <div class="cont">
                      {{ gitDictVal('101000000', ruleForm.dict_sex) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">身高：</div>
                    <div v-if="ruleForm.height" class="cont">
                      {{ ruleForm.height }}cm
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">手机号码：</div>
                    <div class="cont">{{ ruleForm.mobile_phone }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">家属电话：</div>
                    <div class="cont">{{ ruleForm.contact_person_mobile }}</div>
                  </div>
                </div>
                <div class="info-item-box norig">
                  <div class="info-item flex-between">
                    <div class="lable">年龄：</div>
                    <div class="cont">{{ ruleForm.age }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">生日：</div>
                    <div class="cont">{{ ruleForm.birth_date }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">婚姻状况：</div>
                    <div class="cont">
                      {{ gitDictVal('315000000', ruleForm.dict_marry) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">家庭住址：</div>
                    <div class="cont">{{ ruleForm.address }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="userItem">
              <div class="user-tit">病历信息</div>
              <div class="user-info flex-between">
                <div class="info-item-box">
                  <div class="info-item flex-between">
                    <div class="lable">病案号：</div>
                    <div class="cont">{{ ruleForm.case_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">透析频次：</div>
                    <div class="cont">
                      {{
                      gitDictVal('117000000', ruleForm.dict_dialysis_cycle)
                      }}
                    </div>
                  </div>
                  <!-- <div class="info-item flex-between">
                    <div class="lable">门诊号：</div>
                    <div class="cont">{{ ruleForm.menzhen_no }}</div>
                  </div> -->
                  <div class="info-item flex-between">
                    <div class="lable">医保类型：</div>
                    <div class="cont">
                      {{ gitDictVal('103000000', ruleForm.dict_payment_type) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">透析类型：</div>
                    <div class="cont">
                      {{ gitDictVal('210000000', ruleForm.dict_dialysis_type) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">发病原因：</div>
                    <div class="cont">
                      {{ gitDictVal('390000000', ruleForm.dict_ill_reason) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">中心患者：</div>
                    <div class="cont">
                      {{ ruleForm.is_center === 1 ? '是' : '否' }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">床位号：</div>
                    <div class="cont">{{ ruleForm.hospital_bed_no }}</div>
                  </div>
                </div>
                <div class="info-item-box">
                  <div class="info-item flex-between">
                    <div class="lable">病历编码：</div>
                    <div class="cont">{{ ruleForm.medical_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">医保号：</div>
                    <div class="cont">{{ ruleForm.payment_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">住院号：</div>
                    <div class="cont">{{ ruleForm.hospital_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">来源科室：</div>
                    <div class="cont">
                      {{ gitDictVal('156000000', ruleForm.dept) }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">首透时间：</div>
                    <div class="cont">{{ ruleForm.first_hd_time }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">血型：</div>
                    <div class="cont">
                      {{ gitDictVal('104000000', ruleForm.dict_blood_type) }}
                    </div>
                  </div>
                </div>
                <div class="info-item-box norig">
                  <div class="info-item flex-between">
                    <div class="lable">透析卡号：</div>
                    <div class="cont">{{ ruleForm.dialysis_card_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">诊疗卡号：</div>
                    <div class="cont">{{ ruleForm.consultation_no }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">病人来源：</div>
                    <div class="cont">
                      {{
                      gitDictVal('105000000', ruleForm.dict_patient_source)
                      }}
                    </div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">入室时间：</div>
                    <div class="cont">{{ ruleForm.dialysis_center_time }}</div>
                  </div>
                  <div class="info-item flex-between">
                    <div class="lable">透析次数：</div>
                    <div class="cont">{{ ruleForm.dialysis_count }}</div>
                  </div>

                  <div class="info-item flex-between">
                    <div class="lable">RH血型：</div>
                    <div class="cont">
                      {{ gitDictVal('109000000', ruleForm.dict_rh_blood_type) }}
                    </div>
                  </div>
                  <!-- <div class="info-item flex-between">
                    <div class="lable">HIS患者ID：</div>
                    <div class="cont">{{ ruleForm.his_patient_id }}</div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="userItem podBot">
              <div class="user-tit">感染状况</div>
              <div class="infected flex-start">
                <div style="margin-right: 20px">感染状况：</div>
                <div v-for="item in ruleForm.infect_arr" :key="item.id" class="item flex-between">
                  <div class="name">{{ item.disease }}</div>
                  <div v-if="item.infect_type != 0" class="cont">
                    {{ item.infect_type === 1 ? '新' : '转阴' }}
                    {{ item.infect_date }}
                  </div>
                </div>
              </div>
            </div>
            <div class="userItem podBot">
              <div class="user-tit">选择患者标签</div>
              <div class="parient-tag">
                <el-tag v-for="item in ruleForm.patient_tag_list" :key="item.id">{{ item.name }}
                </el-tag>
                <div v-if="
                    ruleForm.patient_tag_list &&
                    ruleForm.patient_tag_list.length <= 0
                  " class="noData">
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <viewDialog v-if="isViewDialog" ref="viewDialogRef" @refreshDataList="getInfoList"></viewDialog>
    <BaseDialog :isshow="showQrcode" height="auto" title="签到码" width="500px" @handleShow="
        (val) => {
          showQrcode = val
        }
      ">
      <div id="printBox">
        <div class="patient-info">
          <div style="font-size: 20px; font-weight: bold;">{{ ruleForm.name }} {{ ruleForm.sex }} {{ ruleForm.age }}
          </div>
        </div>
        <div class="qrcodeBox">
          <qrcode-vue :size="300" :value="Qrcode"></qrcode-vue>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="showQrcode = false">
            确认
          </el-button>
          <el-button v-print="print" class="color-determine" type="primary">
            打印
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
  import { ref, reactive, toRefs, onMounted, nextTick } from 'vue'
  import { useRoute } from 'vue-router'
  import service from '@/utils/request'
  import { getConstHei, gitDictList } from '@/utils/tool.js'
  import QrcodeVue from 'qrcode.vue'
  import patInfo from '@/components/patient/info.vue'
  import viewDialog from '../../viewDialog.vue'
  import BaseDialog from '@/components/Dialog/index.vue'

  export default {
    name: 'patentInfo',
    components: {
      patInfo,
      viewDialog,
      BaseDialog,
      QrcodeVue,
    },
    setup() {
      const viewDialogRef = ref(null)
      const isViewDialog = ref(false)
      const id = ref('')
      const state = reactive({
        ruleForm: {},
        infoHei: '',
        dictList: {},
        patInfoRef: null,
      })
      const print = reactive({
        id: 'printBox',
        popTitle: '签到二维码',
      })
      
      const route = useRoute()
      onMounted(() => {
        state.infoHei = getConstHei()
        id.value = route.params.id
        getDict()
        getInfoData()
      })
      const getInfoList = () => {
        getInfoData()
        state.patInfoRef.getInfoData()
      }

      const getInfoData = async () => {
        let res = await service.post('/api/patient/get_patient_detail', {
          patient_id: id.value,
        })
        if (res.code === 0) {
          state.ruleForm = res.data
          // nextTick(() => {
          //   state.patInfoRef.getList(state.ruleForm)
          // })
        }
      }

      const getDict = async () => {
        const dictLists = await gitDictList([
          '162000000',
          '101000000',
          '102000000',
          '103000000',
          '117000000',
          '315000000',
          '105000000',
          '156000000',
          '104000000',
          '109000000',
          '210000000',
          '106000000',
          '390000000',
          '108000000',
        ])
        state.dictList = {
          ...state.dictList,
          ...dictLists,
        }
      }

      // 字典获取对应值
      const gitDictVal = (item, val) => {
        if (!state.dictList[item]) return
        let name = ''
        state.dictList[item].map((obj) => {
          if (obj.code === val) {
            name = obj.name
          }
        })
        return name
      }
      const showQrcode = ref(false)
      const Qrcode = ref('')
      const openQrcode = async () => {
        let res = await service.post('/api/mini_program/sign_code', {
          patient_id: id.value,
        })
        if (res.code === 0) {
          Qrcode.value = res.data.url
          showQrcode.value = true
        }
      }

      const modParient = () => {
        isViewDialog.value = true
        nextTick(() => {
          viewDialogRef.value.initData(state.ruleForm)
        })
      }
      const InfoThing = (item) => {
        console.log(item)
        id.value = item
        getDict()
        getInfoData()
      }
      return {
        ...toRefs(state),
        isViewDialog,
        viewDialogRef,
        modParient,
        gitDictVal,
        getInfoData,
        getInfoList,
        openQrcode,
        showQrcode,
        Qrcode,
        print,
        InfoThing
      }
    },
  }
</script>

<style lang="scss" scoped>
  .patentInfo {
    margin-top: 8px;
  }

  .podBot {
    padding-bottom: 16px;
  }

  .patInfo {
    width: 200px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 16px;
    text-align: center;
    padding: 20px;
    margin-right: 32px;
  }

  .cont-main {
    flex: 1;

    .userItem {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 16px;

      .user-tit {
        font-size: 18px;
        line-height: 18px;
        font-family: 'Source Han Sans CN-Medium';
        color: #000000;
        border-left: 6px solid #3166ae;
        padding: 0 18px;
        margin-bottom: 16px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .updateBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;

    .color-main {
      background: rgba(49, 102, 174, 0.1);
      border-color: rgba(49, 102, 174, 0.1);
      color: #3166ae;
    }
  }

  .user-info {
    flex-wrap: wrap;

    .info-item-box {
      width: 320px;
      margin-right: 30px;
      // padding: 0 20px;
      padding-left: 20px;
      font-family: 'Source Han Sans CN-Regular';

      &.norig {
        margin-right: 0;
      }

      &.wd-2 {
        width: 670px;
      }

      .info-item {
        font-size: 14px;
        color: #444444;
        margin-bottom: 16px;
      }
    }
  }

  .infected {
    font-size: 14px;
    font-family: 'Source Han Sans CN-Regular';
    color: #444444;
    flex-wrap: wrap;

    .item {
      margin-right: 30px;
    }

    .cont {
      color: #999999;
      margin-left: 30px;
    }
  }

  .parient-tag {
    .el-tag {
      margin-right: 10px;
    }
  }

  .noData {
    color: #999999;
  }

  .qrcodeBox {
    display: flex;
    justify-content: center;
    padding: 24px 0;
  }

  .patient-info {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
</style>